import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpServiceService } from '@services/http-service.service'
import { Select, Store } from '@ngxs/store';
import { Referral, SendReferralEmailDTO } from '@states/my-referral/my-referral.model';

@Injectable({
  providedIn: 'root'
})
export class ReferralService extends HttpServiceService {

  baseUrl: string;
  constructor(
    store: Store,
    http: HttpClient
  ) {
    super(store, http);
    this.baseUrl = environment.lease_path.base_uri + "referrals"
  }


  getMyReferrals(customerId:number) {

    return this.get<Referral[]>(`${this.baseUrl}/${customerId}`);
  }

    sendReferralEmail(newReferral: SendReferralEmailDTO) :Observable<any>{
      var url = this.baseUrl;
      console.log(environment,"environment");
      console.log(url,"url");
      return this.post(url, newReferral)
    }

}

