<h1 class="form-title">
  My Referrals
</h1>

<div class="form-content">

  <div class="row">
    <div class="col-md-8 mt-3">
      <!-- Filter -->
      <ng-container card-table-filter>
        <mat-form-field class="search-filter">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="filter($event)">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </ng-container>
    </div>

    <div class="col-md-4 mt-4 button-container">
      <ng-container card-table-buttons>

        <button mat-stroked-button color="primary" type="button"
          class="referrals-btn" (click)="createReferral()"><mat-icon>add_circle_outline</mat-icon> Generate Your Referral</button>
        <!-- <button mat-stroked-button color="primary" type="button" class="referrals-btn"><mat-icon>mail_outline</mat-icon>
          Send Referral</button> -->
      </ng-container>
    </div>

    <div class="col-md-12 mt-3">
      <ng-container card-table>
        <mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>


          <!-- Code -->
          <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Code </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.code }}</mat-cell>
          </ng-container>

          <!-- Asset ID -->
          <!-- <ng-container matColumnDef="assetId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Asset ID </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.assetId }}</mat-cell>
          </ng-container> -->

          <!-- Base Unit ID -->
          <!-- <ng-container matColumnDef="baseUnitId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Base Unit ID </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.baseUnitId }}</mat-cell>
          </ng-container> -->

          <!-- Recipient Customer ID -->
          <ng-container matColumnDef="recipientEmail">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Recipient Email </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.recipientEmail }}</mat-cell>
          </ng-container>

          <!-- Receiver Name -->
          <ng-container matColumnDef="recipientName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Recipient Name </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.recipientName }}</mat-cell>
          </ng-container>

          <!-- Activated On -->
          <ng-container matColumnDef="sentOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Sent On </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.sentOn | date }}</mat-cell>
          </ng-container>

          <!-- Opened On -->
          <ng-container matColumnDef="openedOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Opened On </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.openedOn | date }}</mat-cell>
          </ng-container>

          <!-- Opened On -->
          <ng-container matColumnDef="appliedOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Applied On </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.appliedOn | date }}</mat-cell>
          </ng-container>

          <!-- approvedOn On -->
          <ng-container matColumnDef="approvedOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Approved On </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.approvedOn | date }}</mat-cell>
          </ng-container>

          <!-- Seated On -->
          <ng-container matColumnDef="seatedOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Seated On </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.seatedOn | date }}</mat-cell>
          </ng-container>

          <!-- rewarded On -->
          <ng-container matColumnDef="rewardedOn">
            <mat-header-cell *matHeaderCellDef mat-sort-header> rewarded On </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.rewardedOn | date }}</mat-cell>
          </ng-container>


          <!-- actions -->
          <!-- <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">

              <button class="" mat-mini-fab color="primary">
                <mat-icon>delete_outline</mat-icon>
              </button>

            </mat-cell>
          </ng-container> -->

          <!-- Header row -->
          <mat-header-row *matHeaderRowDef="columns; sticky: true"></mat-header-row>

          <!-- Data row -->
          <mat-row *matRowDef="let row; columns: columns;"></mat-row>

          <!-- Empty row -->
          <mat-row *matNoDataRow class="empty-row"></mat-row>

        </mat-table>


      </ng-container>

      <!-- Paginator -->
      <!-- <ng-container card-table-paginator>
        <mat-paginator [pageSizeOptions]="[30, 50, 100]" showFirstLastButtons></mat-paginator>
      </ng-container> -->
      <!-- <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2">Lease Required</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Auto Liability (carrier or own authority) and Non-Trucking Liability</td>
            <td>Auto Liability (carrier or own authority) and Non-Trucking Liability</td>
          </tr>
          <tr>
            <td>Physical Damage/ Full Coverage w/ Comp and Collision</td>
            <td>
              ACV or Stated Value: <br>
              Minimum $80,000 if Model Year is 3 Years old or Newer  <br>
              Minimum $70,000 if Model Year is 4 Years old <br>
              Minimum $60,000 if Model Year is 5 Years and older <br>
              $2,500 maximum deductible
            </td>
          </tr>
          <tr>
            <td>Additional Insured</td>
            <td>
              OTR Leasing, LLC <br>
              PO Box 25934 <br>
              Overland Park, KS 66225
            </td>
          </tr>
          <tr>
            <td>Loss Payee</td>
            <td>
              OTR Leasing, LLC <br>
              PO Box 25934 <br>
              Overland Park, KS 66225
            </td>
          </tr>
          <tr>
            <td>Insured Name</td>
            <td>Lessee Must be Listed as Insured</td>
          </tr>
          <tr>
            <td>Further Insurance Requirements</td>
            <td>
              Above coverage is required at all times throughout the lease period. <br>
              All insurance policies must have correct addresses at all times. <br>
              Any changes must be provided to Insurance Administrator
            </td>
          </tr>
        </tbody>
      </table> -->

    </div>

  </div>

</div>

<ng-template #submitCoiDocumentTemplate>
  <!--<div class="container">

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <h4>Submit COI to Berkshire</h4>
      </div>
    </div>

    <div class="row coi-document-content">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <div *ngFor="let file of documents; let i = index">
          <div class="file-link">
            <span>{{ file.file?.name }}</span>
            <span class="flex-expand"></span>
            <mat-icon class="icon" (click)="removeUploadedFile($event, file.file?.name)">cancel</mat-icon>
          </div>
        </div>

      </div>
    </div>

    <div class="row mt-3 mb-3">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button mat-stroked-button color="primary" type="button" class="blue-btn me-4" (click)="onCloseClick()">Close</button>
        <button *ngIf="isEmptyAttachDocument" mat-stroked-button color="primary" type="button" class="float-end ms-3" (click)="triggerFileInput($event)">Add Document</button>
        <button *ngIf="!isEmptyAttachDocument" mat-stroked-button color="primary" type="button" (click)="submit()" class="float-end">Submit</button>
      </div>
    </div>

  </div>-->
</ng-template>
