<nav id="sidebar" class="sidebar js-sidebar">
  <div class="sidebar-content js-simplebar" data-simplebar="init">
    <div class="simplebar-wrapper" style="margin: 0px;">
      <div class="simplebar-height-auto-observer-wrapper"><div class="simplebar-height-auto-observer"></div></div><div class="simplebar-mask">
        <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
          <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style="height: 100%; overflow: hidden scroll;">
            <div class="simplebar-content" style="padding: 0px;">
              <div class="sidebar-brand">
                <span class="align-middle">
                  <img src="assets/images/otr-leasing-logo.png" class="" alt="Vanessa Tucker">
                </span>
              </div>

              <ul class="sidebar-nav">
                <li class="sidebar-header">
                  Pages
                </li>

                <li class="sidebar-item">
                  <div class="sidebar-link" (click)="routerLink('/dashboard')">
                    <mat-icon class="orange" fontIcon="dashboard"></mat-icon> <span class="align-middle">Dashboard</span>
                  </div>
                </li>

                <li class="sidebar-item">
                  <div class="sidebar-link" (click)="routerLink('/payments')">
                    <mat-icon class="orange" fontIcon="receipt_long"></mat-icon> <span class="align-middle">Make A Payment</span>
                  </div>
                </li>

                <mat-expansion-panel class="sidebar-item active" [expanded]="true">
                  <mat-expansion-panel-header class="sidebar-link">
                    <mat-icon class="orange" fontIcon="calculate"></mat-icon><span>Accounts</span>
                  </mat-expansion-panel-header>
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" (click)="routerLink('/accounts/information')">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">Account Information</span>
                    </div>
                  </li>
                </mat-expansion-panel>

                <mat-expansion-panel class="sidebar-item active" [expanded]="false">
                  <mat-expansion-panel-header class="sidebar-link">
                    <mat-icon class="orange" fontIcon="calculate"></mat-icon><span>Leases</span>
                  </mat-expansion-panel-header>
                  <ng-container *ngIf="(leases$ | async) as list">
                    <li class="sidebar-item inner-content-item" *ngFor="let lease of list">
                      <div class="sidebar-link" (click)="routerLeaseLink(lease.leaseId, lease.vin)">
                        <mat-icon class="orange" fontIcon="trip_origin"></mat-icon>
                        <span class="align-middle" title="{{lease.vin}}">Lease {{ lease.leaseId }} VIN# {{lease.vin}} </span>
                      </div>
                    </li>
                  </ng-container>
                </mat-expansion-panel>

                <mat-expansion-panel class="sidebar-item active" [expanded]="true">
                  <mat-expansion-panel-header class="sidebar-link">
                    <mat-icon class="orange" fontIcon="description"></mat-icon><span>Breakdown Resources</span>
                  </mat-expansion-panel-header>
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" (click)="routerLink('/resources/drs-information')">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">DRS Information</span>
                    </div>
                  </li>
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" (click)="routerLink('/resources/warranty-covered-parts')">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">Warranty Covered Parts</span>
                    </div>
                  </li>
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" (click)="routerLink('/resources/warranty-claim-procedure')">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">Warranty Claims & Procedures</span>
                    </div>
                  </li>
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" (click)="routerLink('/resources/fleet-pride')">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">Fleet Pride</span>
                    </div>
                  </li>
                </mat-expansion-panel>

                <li class="sidebar-item">
                  <div class="sidebar-link" (click)="routerLink('/maintenance/quarterly-maintenance-requirements')">
                    <mat-icon class="orange" fontIcon="settings"></mat-icon> <span class="align-middle">Preventive Maintenance</span>
                  </div>
                </li>

                <mat-expansion-panel class="sidebar-item active" [expanded]="true">
                  <mat-expansion-panel-header class="sidebar-link">
                    <mat-icon class="orange" fontIcon="app_registration"></mat-icon><span>Title/Registration Process</span>
                  </mat-expansion-panel-header>
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" (click)="routerLink('/title/faq')">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">Title FAQ</span>
                    </div>
                  </li>
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" (click)="routerLink('/title/truck-registration-info')">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">Truck Registration Info</span>
                    </div>
                  </li>
                </mat-expansion-panel>

                <mat-expansion-panel class="sidebar-item active" [expanded]="true">
                  <mat-expansion-panel-header class="sidebar-link">
                    <mat-icon class="orange" fontIcon="article"></mat-icon><span>Insurance Information</span>
                  </mat-expansion-panel-header>
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" (click)="routerLink('/insurance/insurance-requirements')">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">Insurance Requirements</span>
                    </div>
                  </li>
                  <!--<li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" style="cursor: default">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">View Current Insurance</span>
                    </div>
                  </li>
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" style="cursor: default">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">Update Insurance Information</span>
                    </div>
                  </li>-->
                  <li class="sidebar-item inner-content-item">
                    <div class="sidebar-link" (click)="routerLink('/insurance/report-an-accident')">
                      <mat-icon class="orange" fontIcon="trip_origin"></mat-icon> <span class="align-middle">Report an Accident</span>
                    </div>
                  </li>
                </mat-expansion-panel>

                <li class="sidebar-item">
                  <div class="sidebar-link" (click)="routerLink('/my-referrals')">
                    <mat-icon class="orange" fontIcon="groups"></mat-icon> <span class="align-middle">My Referrals</span>
                  </div>
                </li>

                <li class="sidebar-item">
                  <div class="sidebar-link" (click)="routerLink('/contact')">
                    <mat-icon class="orange" fontIcon="phone_in_talk"></mat-icon> <span class="align-middle">Contact</span>
                  </div>
                </li>

              </ul>


            </div>
          </div>
        </div>
      </div><div class="simplebar-placeholder" style="width: auto; height: 957px;"></div>
    </div><div class="simplebar-track simplebar-horizontal" style="visibility: hidden;"><div class="simplebar-scrollbar" style="width: 0px; display: none;"></div></div><div class="simplebar-track simplebar-vertical" style="visibility: visible;"><div class="simplebar-scrollbar" style="height: 882px; transform: translate3d(0px, 0px, 0px); display: block;"></div></div>
  </div>
</nav>
