import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { catchError, combineLatest, first, Observable, of, Subscription, tap } from 'rxjs';
import { LeaseStateModel } from '@states/lease/lease.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../../services/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AuthState } from '@states/auth/auth.state';
import { auth0User } from '@states/auth/auth.model';
import { CustomerPaymentStateModel } from '@states/payment/payment.model';
import { PaymentAction } from '@states/payment/payment.actions';
import { MaintenanceAction } from '@states/upload-documents/upload-documents.actions';
import { DatePipe } from '@angular/common';
import { GeotabActions } from "@states/geotab";
import { DashboardAction } from '../../../../states/dashboard/dashboard.actions';
import { DashboardState } from '../../../../states/dashboard/dashboard.state';
import { DashboardStateModel } from '../../../../states/dashboard/dashboard.model';
import { MatDialog } from '@angular/material/dialog';
import { SubmitCoiComponent } from '../../components/submit-coi/submit-coi.component';
import { VisibilityService } from '../../../../services/visibility.service';
import { EstimatedPayoff, FinancialActions, FinancialState } from '../../../../states/financial';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements AfterViewInit, OnInit {

  @Select(AuthState.CurrentUser) user$: Observable<auth0User> | undefined;

  dashboard$: Observable<DashboardStateModel>;
  dashboardSubscription: Subscription;

  leases$: Observable<LeaseStateModel[]>;
  leaseSubscription: Subscription;

  payment$: Observable<CustomerPaymentStateModel>;
  paymentSubscription: Subscription;
  firstName: string = "";

  estimatedPayoff$: Observable<EstimatedPayoff | null>;

  ELEMENT_DATA: PeriodicElement[] = [
    { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
    { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
    { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
    { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
    { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
    { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
    { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
    { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
    { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
    { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
    { position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na' },
    { position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg' },
    { position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al' },
    { position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si' },
    { position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P' },
    { position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S' },
    { position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl' },
    { position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar' },
    { position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K' },
    { position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca' },
  ];
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  panelOpenState = false;
  nowdate: Date;

  @Select(AuthState.IsUserInactive) isUserInactive$: Observable<boolean>;

  constructor(private store: Store, private route: ActivatedRoute, private router: Router,
    private commonService: CommonService, private datePipe: DatePipe, private dialog: MatDialog,
    private visibilityService: VisibilityService) {

  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.commonService.startSpinner();

    this.loaddDashboardData();

    this.loadLeaseData();
    this.nowdate = new Date();

    this.hideLoaderIfAllDataIsLoaded();
    this.store.dispatch(new GeotabActions.LoadGeoTabLatest());

  }

  isDataLoaded: boolean = false;
  hideLoaderIfAllDataIsLoaded() {
    combineLatest([this.dashboard$, this.leases$, this.isUserInactive$]).subscribe(([dashboard, leases, isUserInactive]) => {
      if ((dashboard && leases) || isUserInactive) {
        this.isDataLoaded = true;
        this.commonService.stopSpinner();

        if (leases && leases.length > 0)
          this.firstName = leases[0].firstName;
      }
    });
  }

  loaddDashboardData() {
    this.store.dispatch(new DashboardAction.getData())
    this.dashboard$ = this.store.select(DashboardState.GetData);
    this.dashboardSubscription = this.dashboard$.subscribe((dashboard) => {

      this.commonService.bannerConditionAccordingToWeeksDlq(dashboard.weeksDlq);

      if (this.dashboardSubscription) {
        this.dashboardSubscription.unsubscribe();
      }

    });
  }

  async loadLeaseData() {

    //this.store.dispatch(new LeaseAction.GetLeasesData());
    this.leases$ = this.store.select(state => state.lease.leases);

  }

  isACH: boolean = false;
  nextDueDate: Date;

  LoadData(leaseId: string, baseUnitid: number) {
    this.getPaymenById(leaseId);
    this.loadMaintenanceData(baseUnitid);
  }

  getPaymenById(leaseId: string) {
    this.commonService.startSpinner();

    this.store.dispatch(new PaymentAction.GetData(leaseId));
    this.payment$ = this.store.select(state => state.payment);
    this.paymentSubscription = this.payment$?.subscribe(
      data => {
        if (data) {
          this.nextDueDate = data.nextDueDate;
          this.isACH = data.leaseAutoDebitInd == "true" ? true : false;
          this.commonService.stopSpinner();
        }
        if (this.paymentSubscription) {
          this.paymentSubscription.unsubscribe();
        }
      },
      error => {
        this.commonService.stopSpinner();
        console.error('Error:', error);
      }
    )

  }

  formatDate(_date: any): string | null {
    try {
      if (!_date)
        return "";
      // Parse the date string in UTC mode
      const date = new Date(_date.toString());
      // Format the date using Angular DatePipe, treating it as UTC
      return this.datePipe.transform(date, 'MM/dd/yyyy', 'UTC');
    } catch { return ""; }
  }

  isOlderThan120Days(lastServiceDate: string | Date): boolean {
    if (!lastServiceDate)
      return false;
    const today = new Date();
    const date120DaysAgo = new Date();
    date120DaysAgo.setDate(today.getDate() - 120);

    return new Date(lastServiceDate) < date120DaysAgo;
  }


  maintenance$: Observable<any>;
  maintenanceSubscription: Subscription;
  maintenanceDueDate: Date;
  loadMaintenanceData(baseUnitid: number) {
    this.commonService.startSpinner();
    this.store.dispatch(new MaintenanceAction.GetMaintenance(baseUnitid));
    this.maintenance$ = this.store.select(state => state.documents.maintenance);
    this.maintenanceSubscription = this.maintenance$?.subscribe(
      data => {
        if (data) {
          this.maintenanceDueDate = data.dueDate;
          this.commonService.stopSpinner();
        }
        if (this.maintenanceSubscription) {
          this.maintenanceSubscription.unsubscribe();
        }
      },
      error => {
        this.commonService.stopSpinner();
        console.error('Error:', error);
      }
    )

    this.commonService.stopSpinner();
  }

  isLeaseEndDateValid(leaseEndDate: string | Date): boolean {
    const leaseEndDateObj = new Date(leaseEndDate);
    return leaseEndDateObj >= this.nowdate;
  }

  routerLeaseLink(leaseId: string) {
    this.router.navigate(['/payments'], {
      queryParams: { id: leaseId }
    });
    this.commonService.scrollRestoration();
  }
  routeUploadLink(leaseId: string) {
    this.router.navigate(['/maintenance/quarterly-maintenance-requirements'], {
      queryParams: { id: leaseId }
    });
    this.commonService.scrollRestoration();
  }

  ngOnDestroy() {

    this.commonService.hideWeeksDlqBanner();
    if (this.dashboardSubscription) {
      this.dashboardSubscription.unsubscribe();
    }
    if (this.leaseSubscription) {
      this.leaseSubscription.unsubscribe();
    }
    if (this.paymentSubscription) {
      this.paymentSubscription.unsubscribe();
    }
    if (this.maintenanceSubscription) {
      this.maintenanceSubscription.unsubscribe();
    }
  }

  // For Submit COI to Berkshire
  openDialogSubmitCOI(leaseId: string): void {
    const dialogRef = this.dialog.open(SubmitCoiComponent, {
      width: '500px',
      minHeight: '200px',
      data: { leaseId: leaseId },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  // Request Payoff Quote
  estimatedPayoff: EstimatedPayoff = new EstimatedPayoff();
  loadEstimatedPayoffData(leaseId: string) {
    this.commonService.startSpinner();
    this.store.dispatch(new FinancialActions.GetEstimatedPayoff(leaseId));
    this.estimatedPayoff$ = this.store.select(FinancialState.GetEstimatedPayoff);
    this.estimatedPayoff$.pipe(
      first((data) => !!data), // Completes when the first valid data is emitted
      tap((data) => {
        this.estimatedPayoff = data as EstimatedPayoff;
        this.commonService.stopSpinner();
        this.openDialogRequestPayoffQuote(leaseId);
      }),
      catchError((error) => {
        this.commonService.stopSpinner();
        console.error('Error loading estimated payoff:', error);
        return of(null);
      })
    ).subscribe();
  }

  @ViewChild('requestPayoffQuoteTemplate') requestPayoffQuoteTemplate: TemplateRef<any>;
  openDialogRequestPayoffQuote(leaseId: string): void {
    this.dialog.open(this.requestPayoffQuoteTemplate, {
      width: '500px',
      height: '450px',
    });
  }
  onCloseClick(): void {
    this.dialog.closeAll();
  }

  printDialogData(): void {
    window.print();
  }


}
