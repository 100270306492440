import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import {MatXtnSnackbarSuccessModule} from "../snackbar-success";
import {AllSharedComponents} from "./components";
import {AllSharedPipes} from "./pipes";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaskitoDirective} from '@maskito/angular';


@NgModule({
  declarations: [
    ...AllSharedComponents(),
    ...AllSharedPipes()

  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatXtnSnackbarSuccessModule,
    ReactiveFormsModule,
    FormsModule,
    MaskitoDirective
  ],
  exports: [
    ...AllSharedComponents(),
    ...AllSharedPipes()
  ]
})
export class SharedModule { }
