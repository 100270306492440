import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { catchError, firstValueFrom, map, mergeMap, tap, throwError } from 'rxjs';
import { OtrTokenActions } from './otr-token.actions';
import { ApplicationTokenResponseType, IOtrTokenModel } from './otr-token.model';
import { ContactState } from "@states/contact/contact.state";
import { AuthService } from '@auth0/auth0-angular';


@State<IOtrTokenModel>({
  name: 'otrToken',
  defaults: <IOtrTokenModel>{
    loading: true,
  }
})
@Injectable()
export class OtrTokenState implements NgxsOnInit {

  baseUrl: string;
  authorizedKey: string | undefined;

  constructor(
    private http: HttpServiceService,
    private readonly authService: AuthService,
    private store: Store) {
    this.baseUrl = environment.lease_path.base_uri;
  }

  get baseApiUrl(): string {
    return `${this.baseUrl}Token`;
  }

  ngxsOnInit(ctx: StateContext<any>): void {
    this.store.dispatch(new OtrTokenActions.OtrLeasingToken());
  }

  @Selector()
  static getOtrToken(state: IOtrTokenModel): string {
    return state.applicationToken;
  }

  @Selector()
  static IsLoading(state: IOtrTokenModel): boolean {
    return state.loading;
  }

  @Action(OtrTokenActions.Loading)
  onLoading(ctx: StateContext<IOtrTokenModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(OtrTokenActions.Done)
  onDone(ctx: StateContext<IOtrTokenModel>) {
    ctx.patchState({
      loading: false
    });
  }

  @Action(OtrTokenActions.OtrLeasingToken)
  getOtrLeasingToken(ctx: StateContext<IOtrTokenModel>) {

    return ctx.dispatch(new OtrTokenActions.Loading()).pipe(
      mergeMap(() => this.authService.getAccessTokenSilently()),
      tap(token => {
        if (!token) {
          console.error('Auth0 token is expired or not available.');
          return; // Exit if token is not available
        }
        const authorizedKey = token;
        ctx.patchState({
          applicationToken: authorizedKey
        });
        ctx.dispatch(new OtrTokenActions.Done());
      }),
    )

  }
}
