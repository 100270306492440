import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ActivatedCustomerType, IAdminStateModel, ReAssignmentType} from './admin.model';
import {AdminActions} from './admin.actions';
import {environment} from "../../../environments/environment";
import {mergeMap, tap} from "rxjs";
import {HttpServiceService} from "../../services/http-service.service";
import {ConfirmDialogDialogService} from "@shared/components/confirm-dialog";
import {AuthState} from "@states/auth/auth.state";
import {MatXtndSnackbarSuccessService} from "../../modules/snackbar-success";


@State<IAdminStateModel>({
  name: 'admin',
  defaults: <IAdminStateModel>{
    loading: false,
    busy: false,
    busyId: null,
    activatedCustomers: [],
    inactivatedCustomers: [],
    currentActivationRequest: null
  }
})
@Injectable()
export class AdminState {

  constructor(
    private readonly store: Store,
    private readonly http: HttpServiceService,
    private readonly snackbarSuccessService: MatXtndSnackbarSuccessService,
    private readonly confirmationDialogService: ConfirmDialogDialogService
  ) {
  }

  get baseApi() {
    return `${environment.lease_path.base_uri}`;
  }

  get basePath() {
    return 'admin';
  }

  get fullApi() {
    return `${this.baseApi}${this.basePath}`;
  }

  @Selector()
  static IsLoading(state: IAdminStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static IsWorking(state: IAdminStateModel): boolean {
    return state.busy;
  }

  @Selector()
  static getBusyId(state: IAdminStateModel): string | null {
    return state.busyId;
  }

  @Selector()
  static getActivatedCustomers(state: IAdminStateModel): Array<ActivatedCustomerType> {
    return state.activatedCustomers;
  }

  @Selector()
  static getInactiveCustomers(state: IAdminStateModel): Array<ActivatedCustomerType> {
    return state.inactivatedCustomers;
  }

  @Selector()
  static getCurrentActivationRequest(state: IAdminStateModel): Partial<ActivatedCustomerType> | null {
    return state.currentActivationRequest;
  }

  @Action(AdminActions.Done)
  onDone(ctx: StateContext<IAdminStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false,
      busyId: null
    });
  }

  @Action(AdminActions.Loading)
  onLoading(ctx: StateContext<IAdminStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(AdminActions.Working)
  onWorking(ctx: StateContext<IAdminStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(AdminActions.LoadActivatedCustomers)
  loadActivatedCustomers(ctx: StateContext<IAdminStateModel>) {
    return ctx.dispatch(new AdminActions.Loading()).pipe(
      mergeMap(() => this.http.get<Array<ActivatedCustomerType>>(`${this.fullApi}/activated/users`)),
      tap((response: any) => {
          ctx.patchState({
            activatedCustomers: response,
          });
          ctx.dispatch(new AdminActions.Done());
        }
      )
    );
  }

  @Action(AdminActions.LoadInactivatedCustomers)
  loadInactivatedCustomers(ctx: StateContext<IAdminStateModel>) {
    return ctx.dispatch(new AdminActions.Loading()).pipe(
      mergeMap(() => this.http.get<Array<ActivatedCustomerType>>(`${this.fullApi}/inactive/users`)),
      tap((response: any) => {
          ctx.patchState({
            inactivatedCustomers: response,
          });
          ctx.dispatch(new AdminActions.Done());
        }
      )
    );
  }

  @Action(AdminActions.AssignLinkToCurrentUser)
  assignLinkToCurrentUser(ctx: StateContext<IAdminStateModel>, action: AdminActions.AssignLinkToCurrentUser) {
    const {sub} = this.store.selectSnapshot(AuthState.CurrentUser);
    const {customerId} = action.request;
    return this.confirmationDialogService.OnOpen({
      title: 'Assign Link',
      message: 'Please Confirm you would like to Re-Assigned this User to your current profile. This action will remove any prior user Profile from your account. ',
    }).pipe(
      mergeMap(() => {
        const payload = <ReAssignmentType>{
          auth0UserId: sub,
          customerId
        };
        ctx.dispatch(new AdminActions.SetBusyId(customerId));
        return this.http.put(`${this.fullApi}/reassigned/user`, payload)
      }),
      tap(() => {
        this.snackbarSuccessService.Open('User Assigned');
        setTimeout(() => {
          window.location.reload();
        }, 3000)
        ctx.dispatch(
          [
            new AdminActions.LoadActivatedCustomers(),
            new AdminActions.LoadInactivatedCustomers(),
            new AdminActions.Done()
          ]
        );

      })
    )
  }

  @Action(AdminActions.CreateActivation)
  createActivation(ctx: StateContext<IAdminStateModel>, action: AdminActions.CreateActivation) {
    const {sub} = this.store.selectSnapshot(AuthState.CurrentUser);
    const {customerId} = action.request;
    return this.confirmationDialogService.OnOpen({
      title: 'Activation Confirmation',
      message: 'Please Confirm you would like to Create Activation for this User. This action will wipe any prior profile or session from the current user. ',
    }).pipe(
      mergeMap(() => {
        ctx.dispatch(new AdminActions.SetBusyId(customerId));
        const payload = <ReAssignmentType>{
          auth0UserId: sub,
          customerId
        };
        return this.http.put<Partial<ActivatedCustomerType>>(`${this.fullApi}/activate/user`, payload)
      }),
      tap((response) => {
        ctx.dispatch([
          new AdminActions.SetAsCurrentActivationRequest(response)
        ])

      })
    )
  }

  @Action(AdminActions.SetBusyId)
  setBusyId(ctx: StateContext<IAdminStateModel>, action: AdminActions.SetBusyId) {
    ctx.patchState({
      busyId: action.id
    });
  }

  @Action(AdminActions.SetAsCurrentActivationRequest)
  setCurrentActivationRequest(ctx: StateContext<IAdminStateModel>, action: AdminActions.SetAsCurrentActivationRequest) {
    ctx.patchState({
      currentActivationRequest: action.request
    });
  }

  @Action(AdminActions.ClearCurrentActivationRequest)
  clearCurrentActivationRequest(ctx: StateContext<IAdminStateModel>) {
    ctx.patchState({
      currentActivationRequest: null
    });
  }


}
