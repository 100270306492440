import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {LayoutComponent} from './layout.component';
import { ReferralBannerComponent } from './referral-banner/referral-banner.component'
import {RouterModule, Routes} from '@angular/router';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {MatIconModule} from '@angular/material/icon';
import {MainModule} from '../views/main/main.module';
import {SidebarComponent} from './sidebar/sidebar.component';
import {NavbarComponent} from './navbar/navbar.component';
import {MessageComponent} from "../views/public/message/message.component";
import {MaterialModule} from "@material/material.module";
import {LoginGuard} from "../services/login.guard.service";

const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
    path: '', component: LayoutComponent,
    children: [
      {path: '', loadChildren: () => MainModule}
    ]
  },
  {path: 'error-message', component: MessageComponent},
  {
    path: 'admin',
    loadChildren: () => import('../views/public/admin/admin.module').then(m => m.AdminModule),
    canActivate: [LoginGuard]
  },
]

@NgModule({
  declarations: [
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    LayoutComponent,
    ReferralBannerComponent
  ],
  imports: [
    CommonModule,
    NgxUiLoaderModule,
    MatIconModule,
    MaterialModule,
    RouterModule.forChild(routes),
    NgOptimizedImage,
  ]
})
export class LayoutModule {
}
