import {Injectable} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {filter} from "rxjs";
import {
  ConfirmDialogDialogComponent,
  ConfirmDialogDialogParams,
  ConfirmDialogDialogResponse
} from './confirm-dialog-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogDialogService {

  constructor(private readonly dialog: MatDialog) {
  }

  OnOpen(params: ConfirmDialogDialogParams): Observable<ConfirmDialogDialogResponse> {
    const dialogInstance = this.dialog.open(ConfirmDialogDialogComponent, {
      width: '600px',
      data: params,
      disableClose: true,
    });
    return dialogInstance.afterClosed().pipe(filter(x => !!x));
  }
}
