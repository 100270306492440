import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { CustomerState } from '@states/customer/customer.state';
import { MatDialog } from '@angular/material/dialog';
import { AddReferralsDialogComponent } from '@shared/components/add-referrals-dialog/add-referrals-dialog.component'

@Component({
  selector: 'app-referral-banner',
  templateUrl: './referral-banner.component.html',
  styleUrls: ['./referral-banner.component.css']
})
export class ReferralBannerComponent {

  canRender: boolean = false;
  currentCustomerId!: number;
  constructor(private router: Router, private route: ActivatedRoute, private dialog: MatDialog, private store: Store) {

  }

  ngOnInit(): void {
    this.currentCustomerId = this.store.selectSnapshot(CustomerState.GetCustomerId);
    this.canRender = this.router.url == '/dashboard';
  }

  openGenerateYourReferralDialog() {
    const ref = this.dialog.open(
      AddReferralsDialogComponent, { panelClass: 'card-dialog', width: '100%', maxWidth: 800, data: { currentCustomerId: this.currentCustomerId } });
    ref.afterClosed().subscribe((r) => {
      if (r) {
        this.router.navigate(["my-referrals"]);
      }
    });
  }

}
