import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpServiceService } from '../../services/http-service.service';
import { environment } from '../../../environments/environment';
import { CustomerState } from '../customer/customer.state';
import { ContactResponseModel } from './contact.model';
import { AddContact, ContactAction } from './contact.actions';

export interface IContactStateModel {
  contact: ContactResponseModel | null;
}

@Injectable()
  @State<IContactStateModel>({
  name: 'contact',
  defaults: {
    contact: null
  }
})

export class ContactState {
  baseUrl: string;
  authorizedKey: string;

  @Select(CustomerState.GetCustomerId) customer$: Observable<string>
  constructor(private http: HttpServiceService,
              private store: Store)
  {
    this.baseUrl = environment.lease_path.base_uri;
    this.authorizedKey = environment.lease_path.key;
  }
  @Action(AddContact)
  addContact(ctx: StateContext<IContactStateModel>, action: AddContact): Observable<any> {
    const customerId = this.store.selectSnapshot(CustomerState.GetCustomerId);
    let payload = {...action.payload, customerId};
    let url = `${this.baseUrl}Contact/submit`;
      return this.http.postDataWithToken(url, payload).pipe(
        tap((response: any) => {
            ctx.patchState({
              contact: response
            });
          }),
          catchError((error) => {
            return throwError(error);
          })
        );

  }


  @Action(ContactAction.GetData)
  onGetContactData(ctx: StateContext<IContactStateModel>) {

    const url = `${this.baseUrl}Contact/issueTypes`;
    this.http.getDataWithToken(url).pipe(
      tap((response: any) => {
        var res = response;
        ctx.patchState({
          contact: response
        });
      }),
      catchError((error: any) => {
        console.error('Error while fetching contact issueTypes api:', error);
        return throwError(error);
      })
    ).subscribe();

  }
}
