import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxUiLoaderConfig, NgxUiLoaderModule} from "ngx-ui-loader";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {NgxCaptchaModule} from 'ngx-captcha';
import {NgxsModule} from '@ngxs/store';
import {NgxGpAutocompleteModule} from '@angular-magic/ngx-gp-autocomplete';
import {Loader} from '@googlemaps/js-api-loader';
import {SharedModule} from "../app/modules/shared/shared.module";
import {HttpClientModule} from '@angular/common/http';
import {AuthState} from './states/auth/auth.state';
import {AuthModule} from '@auth0/auth0-angular';
import {environment} from '../environments/environment.development';
import {PaymentState} from './states/payment/payment.state';
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {DashboardState} from './states/dashboard/dashboard.state';
import {UploadDocumentsState} from './states/upload-documents/upload-documents.state';
import {DatePipe} from '@angular/common';
import {LeaseState} from './states/lease/lease.state';
import {CustomerState} from './states/customer/customer.state';
import {OtrTokenState} from './states/otr-token/otr-token.state';
import {ContactState} from './states/contact/contact.state';
import {AccountState} from './states/account/account.state';
import {MessageViewState} from "@states/message-view/message-view.state";
import {MaintenanceState} from "@states/maintenance";
import {GeotabState} from "@states/geotab";
import {GeoTabModule} from "./modules/geo-tab/geo-tab.module";
import { InsuranceState } from './states/insurance';
import { FinancialState } from './states/financial';
import {AdminState} from "@states/admin/admin.state";


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#FF6720",
  "bgsOpacity": 0.5,
  "bgsPosition": "center-center",
  "bgsSize": 60,
  "bgsType": "rectangle-bounce",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#ffffff",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "rectangle-bounce",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40,40,40,0.8)",
  "pbColor": "#FF6720",
  "pbDirection": "ltr",
  "pbThickness": 8,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    SharedModule,
    NgxGpAutocompleteModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        audience: environment.auth0.audience
      }
    }),
    NgxsModule.forRoot([
      OtrTokenState,
      AuthState,
      PaymentState,
      DashboardState,
      UploadDocumentsState,
      LeaseState,
      CustomerState,
      ContactState,
      AccountState,
      MessageViewState,
      MaintenanceState,
      InsuranceState,
      FinancialState,
      GeotabState,
      AdminState
    ]),
    GeoTabModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxMaskDirective, NgxMaskPipe
  ],
  providers: [provideNgxMask(), DatePipe,
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyDEq-9vJqYuh2Z-lOj4SICDHjcD0y09LdI',
        libraries: ['places']
      })
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
