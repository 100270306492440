import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { CommonService } from '../../../../../services/common.service';
import { HttpServiceService } from '../../../../../services/http-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Referral } from '@states/my-referral/my-referral.model';
import { ReferralService } from '@services/referral.service'
import { AddReferralsDialogComponent } from '@shared/components/add-referrals-dialog/add-referrals-dialog.component'
import { CustomerState } from '@states/customer/customer.state';

@Component({
  selector: 'app-contact',
  templateUrl: './my-referrals.component.html',
  styleUrls: ['./my-referrals.component.css']
})
export class MyReferralsComponent {

  data: FormGroup;

  toggle: boolean = true;
  chatTime: any;// = "Monday, 1:27 PM";

  dataSource = new MatTableDataSource<Referral>([]);

  currentCustomerId!: number;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  columns: string[] = [
    'code',
    'recipientName',
    'recipientEmail',
    'sentOn',
    'openedOn',
    'appliedOn',
    'approvedOn',
    'seatedOn',
    'rewardedOn',
    // 'actions',
  ]

  constructor(private store: Store, private http: HttpServiceService,
    private commonService: CommonService, private renderer: Renderer2,
    private elementRef: ElementRef, private datePipe: DatePipe,
    private referralService: ReferralService,
    private dialog: MatDialog,
  ) {
    const date = new Date();
    let currentDay = this.datePipe.transform(date, 'EEEE')?.toString();
    let currentTime = this.datePipe.transform(date, 'h:mm a');
    this.chatTime = currentDay + ", " + currentTime;

  }

  ngOnInit() {
    this.currentCustomerId =  this.store.selectSnapshot(CustomerState.GetCustomerId);
    this.commonService.startSpinner();
    this.loadFormControls();
    this.loadReferralsData();
  }

  ngOnDestroy() {

  }

  loadFormControls() {
    this.data = new FormGroup({
      lease: new FormControl('', [Validators.required]),
      issueType: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });
  }
  getErrorMessage(dataObject: any) {
    if (dataObject != null) {
      if (this.data.get(dataObject)?.hasError('required')) {
        return `You must enter a value`;
      } else if (this.data.get(dataObject)?.hasError('email')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('numberField')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('pattern')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('maxlength')) {
        return `You must enter a max valid length`;
      } else if (this.data.get(dataObject)?.hasError('minlength')) {
        return `You must enter a min valid length`;
      } else if (this.data.get(dataObject)?.hasError('max')) {
        return `You must enter a max valid number`;
      }
    }
    return '';
  }

  loadReferralsData() {
    this.referralService.getMyReferrals(this.currentCustomerId).subscribe(data => {
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      if(data)
        this.currentCustomerId = data[0].senderCustomerId;
    }, error => { }, () => {
      this.commonService.stopSpinner();
    })
  }


  keyExists(key: any, itemList: { key: string, value: string }[]): boolean {
    return itemList.some(item => item.key === key);
  }

  onSubmit() {
    //this.isValidationEnable = true;
    if (this.data.invalid)
      return;

    this.commonService.startSpinner();

  }

  resetForm() {
    //this.isValidationEnable = false;
    this.data.reset({}, { emitEvent: false });
    //Object.keys(this.data.controls).forEach(key => {
    //  this.data.get(key)?.setErrors(null);
    //});
  }

  toggleChat() {
    this.toggle = !this.toggle;
  }

  filter(event: Event) {
    const target = event.target as HTMLInputElement;
    this.dataSource.filter = target.value;
  }

  createReferral() {
    const ref = this.dialog.open(
      AddReferralsDialogComponent, { panelClass: 'card-dialog', width: '100%', maxWidth: 800, data: { currentCustomerId: this.currentCustomerId} });
    ref.afterClosed().subscribe((r) => {
      this.loadReferralsData();
      //this.getReferrals();
    });
  }

}
