<nav class="navbar navbar-expand navbar-light navbar-bg">
  <a class="sidebar-toggle js-sidebar-toggle">
    <i class="hamburger align-self-center"></i>
  </a>

  <div class="navbar-collapse collapse">
    <ul class="navbar-nav navbar-align">

      <ng-container *ngIf="user$ | async as user; else loginUser">

        <li class="d-none d-sm-inline-block special-padding" >
          <ng-container *ngIf="lowerEnvironmentTarget; else productionTargetEnvironment">
            <img [src]="user.picture" [matMenuTriggerFor]="menu" class="avatar img-fluid rounded me-1 cursor-pointer" alt=""> <span class="text-dark">{{ user.name }}</span>

            <mat-menu #menu="matMenu">
              <button (click)="navigateToAdministration()" mat-menu-item>
                <mat-icon>settings</mat-icon>
                <span>Administration</span>
              </button>
            </mat-menu>
          </ng-container>
          <ng-template #productionTargetEnvironment>
            <img [src]="user.picture"  class="avatar img-fluid rounded me-1 cursor-pointer" alt=""> <span class="text-dark">{{ user.name }}</span>
          </ng-template>
        </li>

        <li><button mat-stroked-button color="primary" class="login-btn" (click)="logout()">Log Out</button></li>

      </ng-container>

      <ng-template #loginUser>
        <!--<button mat-stroked-button color="primary" class="login-btn"  (click)="login()">Login</button>-->
      </ng-template>


    </ul>
  </div>
</nav>
<ng-container *ngIf="isUserInactive$ | async">
  <div class="inactive-user">
    Your account is currently inactive or you have an invalid invitation. Please contact the administrator for assistance.
  </div>
</ng-container>
<ng-container *ngIf="showPastDueBanner">
  <div class="customer-past-due">
    Customer is past due.
  </div>
</ng-container>
<ng-container *ngIf="showCurrentPaymentBanner">
  <div class="customer-current-payment">
   Customer is current on payments.
  </div>
</ng-container>
