import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaskitoOptions } from '@maskito/core';
import { CommonService } from '@services/common.service';
import { ReferralService } from '@services/referral.service'
//import { AddReferralsService } from './add-referrals-dialog.service'

import { Referral, SendReferralEmailDTO } from '@states/my-referral/my-referral.model';


export type AddReferralsDialogParams = {
  currentCustomerId: number;
}
export type AddReferralsDialogResponse = {}

export type codeGenerationDTO = {}




@Component({
  selector: 'app-add-referrals-dialog',
  templateUrl: 'add-referrals-dialog.component.html',
  styleUrls: [`add-referrals-dialog.component.scss`],
})
export class AddReferralsDialogComponent {
  readonly phoneMask: MaskitoOptions = {
    //mask: ['+', '1', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,],
    mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  };

  form!: FormGroup;
  loadingSave = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: AddReferralsDialogParams,
    private readonly dialogRef: MatDialogRef<AddReferralsDialogComponent>,
    private _fb: FormBuilder,
    private commonService: CommonService,
    private referralsService: ReferralService
  ) {
  }

  ngOnInit() {
    this.form = this._fb.group({
      code: [''],
      referralName: ['', [Validators.required, Validators.maxLength(200)]],
      referralEmail: ['', [Validators.required, Validators.maxLength(100), Validators.email]],
      referralPhone: ['', [Validators.maxLength(30)]]
    });
  }

  get code() { return this.form.get('code') as any; }
  get referralName() { return this.form.get('referralName') as any; }
  get referralEmail() { return this.form.get('referralEmail') as any; }
  get referralPhone() { return this.form.get('referralPhone') as any; }


  confirm() {
    this.dialogRef.close(true);
  }
  cancel() {
    this.dialogRef.close(false);
  }

  generateCodeSendEmail() {
    this.loadingSave = true;
    this.commonService.startSpinner();
    //this.commonService.openSnackBar("Code generated and sent successfully", '', 'success');
    //this.commonService.openSnackBar("Error: Could not generate code",'', 'error');

    if (!this.form.valid) {
      this.commonService.openSnackBar("Invalid form", '', 'error');
      return;
    }

    //const token = this.store.selectSnapshot(OtrTokenState.getOtrToken);
    this.loadingSave = true;
    let newReferralEmail: SendReferralEmailDTO = {
      customerSenderId: this.data.currentCustomerId,
      //referralId: this.referralId,
      referralEmail: this.form.value.referralEmail,
      referralName: this.form.value.referralName,
      referralPhone: this.form.value.referralPhone
    };
    this.referralsService.sendReferralEmail(newReferralEmail).subscribe(r => {
      if (r.success) {
        this.commonService.openSnackBar("Code Generated and Email Sent Successfully", '', 'success');
        //this.form.reset();
        this.dialogRef.close(true);
      }

      this.loadingSave = false;
      this.commonService.stopSpinner();
    }, error => {
      this.commonService.openSnackBar("An error occured trying to save", '', 'error');
      this.loadingSave = false;
      this.commonService.stopSpinner();
    }, () => {
      this.loadingSave = false;
      this.commonService.stopSpinner();
    })

  }


}
