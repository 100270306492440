<div *ngIf="canRender == true"  class="referral-banner">
  <div class="dashes dashes-top-left"> <svg height="20" width="160" class="mt-4 text-medium-blue">
      <g fill="none" stroke="currentColor" stroke-width="10">
        <path stroke-dasharray="25,15" d="M5 8 l215 0"></path>
      </g>
    </svg> </div>
  <div class="banner-content">
    <h1>Refer a friend, save on your lease!</h1>
    <p>Have a friend interesting in becoming their own boss? Refer them to OTR Leasing and save money on your
      next payment.</p>
    <div>
      <button mat-stroked-button color="primary" class="generate-referral-button" (click)="openGenerateYourReferralDialog()">Generate Your Referral
        code</button>
    </div>
  </div>
  <div class="dashes dashes-bottom-right"> <svg height="20" width="160" class="mt-4 text-medium-blue">
      <g fill="none" stroke="currentColor" stroke-width="10">
        <path stroke-dasharray="25,15" d="M5 8 l215 0"></path>
      </g>
    </svg> </div>
</div>
