import {Component, Inject} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export type ConfirmDialogDialogParams = {
  messageCentered?: boolean;
  title: string;
  message: string
}
export type ConfirmDialogDialogResponse = {}

@Component({
  selector: 'confirm-dialog-dialog',
  templateUrl: 'confirm-dialog-dialog.component.html',
  styleUrls: [`confirm-dialog-dialog.component.scss`]
})
export class ConfirmDialogDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmDialogDialogParams,
    private readonly dialogRef: MatDialogRef<ConfirmDialogDialogComponent>,
  ) {
  }
  get centered() {
    return (this.data?.messageCentered ?? false) ? `confirm-dialog-action-body-centered` : ``;
  }
  confirm() {
    this.dialogRef.close(true);
  }
  cancel() {
    this.dialogRef.close(false);
  }


}
