import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngxs/store';
import {EMPTY, tap} from 'rxjs';
import {CustomerActions} from '@states/customer/customer.actions';
import {CustomerState} from "@states/customer/customer.state";
import {MessageViewActions} from "@states/message-view/message-view.actions";
import {CustomMessageKeys} from "@states/message-view/message-view.model";


export const LoginSignUpGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store);
  const router = inject(Router);
  let inviteId = route.queryParamMap.get('inviteid');
  console.log(inviteId)
  if (inviteId){
    store.dispatch(new CustomerActions.SetInviteId(inviteId as string));
    return store.dispatch(new CustomerActions.GetActivationTokenStatus(inviteId as string)).pipe(
      tap((response) => {
        const status = store.selectSnapshot(CustomerState.IsInviteOfActiveStatus);
        if(status){

          router.navigate(['/']).then(r => ({}));

          return;
        }
        store.dispatch(new MessageViewActions.ApplyMessage(CustomMessageKeys.ExpiredInvitation));
        router.navigate(['/error-message']).then(r => ({}));
        return;

      })
    );

  }

  store.dispatch(new MessageViewActions.ApplyMessage(CustomMessageKeys.MissingInvitationId));
  router.navigate(['/error-message']).then(r => ({}));
  return EMPTY;


}
