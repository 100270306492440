import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
  APIs,
  defaultPermissionKeyPayload,
  GeoTabApiKey,
  GeoTabMapPingType,
  GeoTabMileageCalcType, GeoTabTelematicType,
  GeotabType,
  IGeotabStateModel
} from './geotab.model';
import {GeotabActions} from './geotab.actions';
import {mergeMap, tap} from "rxjs";
import {PageEvent} from "@angular/material/paginator";

@State<IGeotabStateModel>({
  name: 'geotab',
  defaults: <IGeotabStateModel>{
    loading: false,
    busy: false,
    current: null,
    latestGeoTab: [],
    latestByLeases: [],
    paginationPingState: {pageIndex: 0, pageSize: 10, length: 0},
    pingsPage: [],
    lastDaysCalculation: null,
    telematics: []
  }
})
@Injectable()
export class GeotabState {

  constructor(
    private readonly httpClient: HttpClient
  ) {
  }

  get basePath() {
    return 'https://integration-broker-function.azurewebsites.net/api/geotab-api-integration-historical';
  }
  get rootPath() {
    return 'https://integration-broker-function.azurewebsites.net/api';
  }

  @Selector()
  static IsLoading(state: IGeotabStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static IsWorking(state: IGeotabStateModel): boolean {
    return state.busy;
  }

  @Selector()
  static getCurrent(state: IGeotabStateModel): GeotabType | null {
    return state.current;
  }

  @Selector()
  static getLatestGeoTab(state: IGeotabStateModel): Array<GeotabType> {
    return state.latestGeoTab;
  }

  @Selector()
  static getLatestGeoTabLeases(state: IGeotabStateModel): Array<GeotabType> {
    return state.latestByLeases;
  }

  @Selector()
  static getPaginationPingState(state: IGeotabStateModel): Partial<PageEvent> {
    return state.paginationPingState;
  }

  @Selector()
  static getPingsPage(state: IGeotabStateModel): Array<GeotabType> {
    return state.pingsPage;
  }
  @Selector()
  static getMapPings(state: IGeotabStateModel): Array<GeoTabMapPingType> {
    const pings = state.pingsPage.map(l => ({
      lat: l.latitude,
      lng: l.longitude,
      title: new Date(l.createdDate).toDateString()
    }));
    return pings;
  }
  @Selector()
  static getLastDaysCalculation(state: IGeotabStateModel): { [key: string]: number } | null {
    return state.lastDaysCalculation
  }
  @Selector()
  static getTelematics(state: IGeotabStateModel): Array<GeoTabTelematicType> {
    return state.telematics;
  }
  @Selector()
  static HasTelematics(state: IGeotabStateModel): boolean {
    return state.telematics?.length > 0;
  }
  @Action(GeotabActions.Done)
  onDone(ctx: StateContext<IGeotabStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(GeotabActions.Loading)
  onLoading(ctx: StateContext<IGeotabStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(GeotabActions.Working)
  onWorking(ctx: StateContext<IGeotabStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(GeotabActions.LoadGeoTabLatest)
  loadGeoTabLatest(ctx: StateContext<IGeotabStateModel>) {
    const payload = {
      key: GeoTabApiKey
    }
    let params = new HttpParams({fromObject: {...payload}});

    return ctx.dispatch(new GeotabActions.Loading())
      .pipe(
        mergeMap(() => {
          return this.httpClient.get<Array<GeotabType>>(`${this.basePath}`, {params});
        }),
        tap((latestGeoTab) => {
          ctx.patchState(
            {latestGeoTab}
          )

          ctx.dispatch(new GeotabActions.Done())
        })
      )
  }

  @Action(GeotabActions.LoadGeoTabByLeases)
  loadGeoTabByLeases(ctx: StateContext<IGeotabStateModel>, {leaseId}: { leaseId: Array<string> }) {
    let {paginationPingState} = ctx.getState();
    const payload = {
      key: GeoTabApiKey,
      leaseId: leaseId
    }
    let params = new HttpParams({fromObject: {...payload}});

    return ctx.dispatch(new GeotabActions.Loading())
      .pipe(
        mergeMap(() => {
          return this.httpClient.get<Array<GeotabType>>(`${this.basePath}`, {params});
        }),
        tap((latestByLeases) => {
          paginationPingState.length = latestByLeases.length;
          ctx.patchState(
            {latestByLeases, paginationPingState}
          )
          ctx.dispatch([
            new GeotabActions.LoadPingsPage(),
            new GeotabActions.Done()
          ])
        })
      )
  }

  @Action(GeotabActions.SetPingPaginationState)
  loadPingPagePaginationState(ctx: StateContext<IGeotabStateModel>, action: GeotabActions.SetPingPaginationState) {
    let {paginationPingState} = ctx.getState();
    const {length, ...pagination} = action.request;
    paginationPingState = {...paginationPingState, ...pagination };
    ctx.patchState({paginationPingState});
    return ctx.dispatch(new GeotabActions.LoadPingsPage());
  }

  @Action(GeotabActions.LoadPingsPage)
  loadPingsPage(ctx: StateContext<IGeotabStateModel>) {
    const {paginationPingState, latestByLeases} = ctx.getState();
    const {pageIndex, pageSize} = paginationPingState;
    const pingsPage = [...latestByLeases].splice(pageIndex! * pageSize!, pageSize);
    ctx.patchState({pingsPage});
  }

  @Action(GeotabActions.LoadLeaseLastDayCalculation)
  loadLeaseLastDayCalculation(ctx: StateContext<IGeotabStateModel>, {leaseId}: { leaseId: string }) {
    const {lastDaysCalculation: originalLeastDayCalculation} = ctx.getState();
    const payload = {
      key: GeoTabApiKey,
      lease: leaseId,
      days: 7
    }
    let params = new HttpParams({fromObject: {...payload}});

    return ctx.dispatch(new GeotabActions.Loading())
      .pipe(
        mergeMap(() => {
          return this.httpClient.get<GeoTabMileageCalcType>(`${this.rootPath}/geotab-api-utility-mileage-calc`, {params});
        }),
        tap((lastDaysCalculation) => {
          const payload = {};
          // @ts-ignore
          payload[leaseId] = lastDaysCalculation.mileage;
          ctx.patchState(
            {lastDaysCalculation: {...originalLeastDayCalculation, ...payload}}
          )
          ctx.dispatch(new GeotabActions.Done())
        })
      )
  }

  @Action(GeotabActions.LoadTelematic)
  onLoadTelematic(ctx: StateContext<IGeotabStateModel>, {vin}: { vin: string }) {
    const payload = { ...defaultPermissionKeyPayload, vin }

    return ctx.dispatch(new GeotabActions.Loading()).pipe(
      mergeMap(() =>
        this.httpClient.post<Array<GeoTabTelematicType>>(APIs.Telematics, payload)
      ),
      tap((telematics) => {
        ctx.patchState({ telematics });
        ctx.dispatch(new GeotabActions.Done());
      })
    );
  }

}
