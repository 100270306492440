import {Component, OnInit} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {Observable} from "rxjs";
import {auth0User} from '@states/auth/auth.model';
import {AuthState} from '@states/auth/auth.state';
import {AuthActions} from '@states/auth/auth.actions';
import {VisibilityService} from '../../services/visibility.service';
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Select(AuthState.CurrentUser) user$: Observable<auth0User> | undefined;
  @Select(AuthState.IsDone) done$: Observable<boolean> | undefined;
  @Select(AuthState.IsUserInactive) isUserInactive$: Observable<boolean>;
  production = environment.production;

  showCurrentPaymentBanner = false;
  showPastDueBanner = false;

  constructor(
    private readonly store: Store,
    private visibilityService: VisibilityService,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
    this.visibilityService.currentPayment$.subscribe(
      (isVisible) => (this.showCurrentPaymentBanner = isVisible)
    );
    this.visibilityService.pastDue$.subscribe(
      (isVisible) => (this.showPastDueBanner = isVisible)
    );
  }

  get lowerEnvironmentTarget() {
    return !environment.production;
  }

  logout() {
    this.store.dispatch(new AuthActions.LogOut());
  }

  login() {
    this.store.dispatch(new AuthActions.Login());
  }

  navigateToAdministration() {
    this.router.navigate(['/admin']).then(r => {
    });
  }
}
