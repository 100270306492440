
<div mat-dialog-content class="confirm-dialog-action-core">
  <div class="confirm-dialog-action-body">
    <p [ngClass]="centered">{{data.message}}</p>
  </div>
  <div class="confirm-dialog-action-items">
    <button mat-button type="button" mat-raised-button color="primary" (click)="confirm()" cdkFocusInitial>OK</button>
    <button mat-button type="button" color="warn" (click)="cancel()">Cancel</button>
  </div>
</div>
