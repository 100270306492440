import {Action, Select, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpServiceService} from "../../services/http-service.service";
import {environment} from "../../../environments/environment";
import {EMPTY, Observable, catchError, mergeMap, tap, throwError} from "rxjs";
import { EscrowStateModel, EstimatedPayoff, IFinancialStateModel, PaymentActivityStateModel } from './financial.model';
import { FinancialActions } from './financial.actions';
import { CustomerState } from '../customer/customer.state';

@State<IFinancialStateModel>({
  name: 'financial',
  defaults: <IFinancialStateModel>{
    loading: false,
    busy: false,
    escrow: null,
    paymentActivity: null,
    estimatedPayoff: null,
  }
})
@Injectable()
export class FinancialState {

  @Select(CustomerState.GetCustomerId) customer$: Observable<string>

  constructor(
    private readonly store: Store,
    private readonly http: HttpServiceService
  ) {
  }

  get baseApi() {
    return `${environment.lease_path.base_uri}` ;
  }

  @Selector()
  static IsLoading(state: IFinancialStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static IsWorking(state: IFinancialStateModel): boolean {
    return state.busy;
  }

  @Selector()
  static GetEscrow(state: IFinancialStateModel): EscrowStateModel | null {
    return state.escrow;
  }

  @Selector()
  static GetPaymentActivityByCustomer(state: IFinancialStateModel): PaymentActivityStateModel[] | null {
    return state.paymentActivity;
  }

  @Selector()
  static GetEstimatedPayoff(state: IFinancialStateModel): EstimatedPayoff | null {
    return state.estimatedPayoff;
  }

  @Action(FinancialActions.Done)
  onDone(ctx: StateContext<IFinancialStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(FinancialActions.Loading)
  onLoading(ctx: StateContext<IFinancialStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(FinancialActions.Working)
  onWorking(ctx: StateContext<IFinancialStateModel>) {
    ctx.patchState({
      busy: true
    });
  }


  @Action(FinancialActions.GetEscrowData)
  onGetData(ctx: StateContext<IFinancialStateModel>) {
    const customerId = this.store.selectSnapshot(CustomerState.GetCustomerId);
    if (!customerId)
      return EMPTY;

    const url = `${this.baseApi}Financial/escrow/transactions/${customerId}`;
    return this.http.getDataWithToken(url).pipe(
      tap((data: EscrowStateModel | undefined) => {
        if (!data)
          return;

        ctx.patchState({ escrow: data });
      }),
      catchError((error) => {
        console.error('Error while fetching escrow transactions API:', error.message);
        return throwError(() => error);
      })
    );
  }


  @Action(FinancialActions.GetPaymentActivityByCustomerData)
  onGetPaymentActivityByCustomer(ctx: StateContext<IFinancialStateModel>) {
    const customerId = this.store.selectSnapshot(CustomerState.GetCustomerId);
    if (!customerId)
      return EMPTY;

    const url = `${this.baseApi}Financial/payment/activity/byCustomer/${customerId}`;
    return this.http.getDataWithToken(url).pipe(
      tap((data: PaymentActivityStateModel[] | undefined) => {
        if (!data)
          return;

        data = data.filter(x => x.leaseFinType === "Payment" || x.leaseFinType === "Invoiced");
        ctx.patchState({ paymentActivity: data });
      }),
      catchError((error) => {
        console.error('Error while fetching payment activity API:', error.message);
        return throwError(() => error);
      })
    );
  }

  @Action(FinancialActions.GetEstimatedPayoff)
  onGetEstimatedPayoffData(ctx: StateContext<IFinancialStateModel>, action: FinancialActions.GetEstimatedPayoff) {
    const customerId = this.store.selectSnapshot(CustomerState.GetCustomerId);

    if (customerId) {
      const url = `${this.baseApi}Financial/estimated-payoff/${action.leaseId}`;
      return ctx.dispatch(new FinancialActions.Loading()).pipe(
        mergeMap(() => this.http.getDataWithToken(url)),
        tap((data: EstimatedPayoff | undefined) => {
          ctx.patchState({ estimatedPayoff: data });  
          ctx.dispatch(new FinancialActions.Done());
        }),
        catchError((error) => {
          console.error('Error while fetching estimated payoff API:', error.message);
          return throwError(() => error);
        })
      )
    }
    return EMPTY;

  }




}
