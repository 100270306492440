<!--
<div mat-dialog-content class="confirm-dialog-action-core">
  <div class="confirm-dialog-action-body">
    <p [ngClass]="centered">{{data.message}}</p>
  </div>
  <div class="confirm-dialog-action-items">
    <button mat-button type="button" mat-raised-button color="primary" (click)="confirm()" cdkFocusInitial>OK</button>
    <button mat-button type="button" color="warn" (click)="cancel()">Cancel</button>
  </div>
</div> -->


<div mat-dialog-content class="confirm-dialog-action-core">
  <h1 class="form-title">
    Generate New Referral
  </h1>
  <div class="card-body mt-2 mb-3">

    <form class="row mb-4" [formGroup]="form">


      <div class="col-md-12">
        <mat-form-field class="example-form-field">
          <mat-label>Referral Name</mat-label>
          <input matInput type="text" formControlName="referralName">
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="example-form-field">
          <mat-label>Referral email</mat-label>
          <input matInput type="text" formControlName="referralEmail">
          <mat-error *ngIf="referralEmail.errors && referralEmail.errors.maxlength">
            value has exceeded the maximum size
        </mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="example-form-field">
          <mat-label>Referral phone</mat-label>
          <input matInput type="text" formControlName="referralPhone" [maskito]="phoneMask">
        </mat-form-field>
      </div>

      <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>

        <button mat-raised-button color="primary" (click)="generateCodeSendEmail()" inlineSpinner
           [disabled]="form.invalid  || loadingSave">Send Emails</button>
      </div>
    </form>
  </div>
</div>
