import {ActivatedCustomerType} from "@states/admin/admin.model";

export namespace AdminActions {
  export class Loading {
    static readonly type = '[Admin] Set As Loading';
  }

  export class Working {
    static readonly type = '[Admin] Set As Working';
  }

  export class Done {
    static readonly type = '[Admin] Set As Done';
  }

  export class LoadActivatedCustomers {
    static readonly type = '[Admin] Load Activated Customers';
  }

  export class LoadInactivatedCustomers {
    static readonly type = '[Admin] Load Inactivated Customers';
  }

  export class AssignLinkToCurrentUser {
    static readonly type = '[Admin] Assign Link To Current User';

    constructor(public readonly request: { customerId: string }) {
    }
  }

  export class CreateActivation {
    static readonly type = '[Admin] Create Activation';

    constructor(public readonly request: { customerId: string }) {
    }
  }

  export class SetBusyId {
    static readonly type = '[Admin] Set Busy Idle';

    constructor(public readonly id: string) {
    }
  }
  export class SetAsCurrentActivationRequest {
    static readonly type = '[Admin] Set As Current Activation Request';
    constructor(public readonly request: Partial<ActivatedCustomerType>) {
    }
  }
  export class ClearCurrentActivationRequest {
    static readonly type = '[Admin] Clear Current Activation Request';
  }


}


