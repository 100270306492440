import {Component} from '@angular/core';
import {MatCardModule} from "@angular/material/card";
import {MatIconModule} from "@angular/material/icon";
import {MessageViewState} from "@states/message-view/message-view.state";
import {Select} from "@ngxs/store";
import {Observable} from "rxjs";
import {MessageConfig} from "@states/message-view/message-view.model";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'message',
  standalone: true,
  templateUrl: 'message.component.html',
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule
  ],
  styleUrls: [`message.component.scss`]
})
export class MessageComponent {
  @Select(MessageViewState.getMessageConfig) messageConfig$: Observable<MessageConfig>;

  constructor(private router: Router) {
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }

}
